import React, { useEffect, useMemo } from 'react';

import { useAppSelector } from '../../../redux/hooks';
import { applicationSelectors } from '../../../redux/application/application.slice';
import { CommunicationType } from '../../../redux/application/application.types';
import { useNavigate } from 'react-router-dom';

interface StatusNotificationProps {
  type?: CommunicationType;
}

const EventSourceHandler = ({ type }: StatusNotificationProps) => {
  const token = useAppSelector(applicationSelectors.token);
  const navigate = useNavigate();

  const API_URL = useMemo(() => {
    switch (type) {
      case 'EMAIL': {
        if (!token) return undefined;

        return `/api/crm/registrations/${token}/email/status`;
      }
      default:
        return undefined;
    }
  }, [type, token]);

  const REDIRECT_URL = useMemo(() => {
    switch (type) {
      case 'EMAIL': {
        return `/register/email/failed`;
      }
      default:
        return '/';
    }
  }, [type]);

  useEffect(() => {
    let statusEventSource: EventSource;

    if (API_URL) {
      statusEventSource = new EventSource(API_URL, {});

      statusEventSource.onmessage = ({ data }) => {
        const { status } = JSON.parse(data);

        switch (status) {
          case 'FAILED': {
            navigate(REDIRECT_URL);
            break;
          }
          default:
            break;
        }
      };

      statusEventSource.onerror = () => {
        statusEventSource.close();
      };
    }

    return () => {
      if (statusEventSource) {
        statusEventSource.close();
      }
    };
  }, []);

  return <></>;
};
export default EventSourceHandler;
