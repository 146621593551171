import React from 'react';
import './Description.css';

interface IDescriptionProps {
  title?: string | JSX.Element;
  titleClass?: string;
  description?: string | JSX.Element;
}

const Description = ({ title, titleClass, description }: IDescriptionProps) => {
  return (
    <div className={'description'}>
      {title && <h2 className={titleClass}>{title}</h2>}
      <div className={'description--content'}>{description}</div>
    </div>
  );
};

export default Description;
