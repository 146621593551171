import React from 'react';
import './Card.css';
import clsx from 'clsx';

interface ICardProps {
  children?: JSX.Element | JSX.Element[] | string;
  className?: string;
}

const Card = ({ children, className }: ICardProps) => {
  return <div className={clsx('card', className)}>{children}</div>;
};

export default Card;
