import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  IAddSubscriptionErrorPayload,
  IAddSubscriptionPayload,
  IUnsubscribeTopicErrorPayload,
  IUnsubscribeTopicPayload,
  ISubscription,
} from './subscriptions.types';
import { IError } from '../types';

interface ISubscriptionsState {
  error?: IError;
  loading: boolean;
  subscriptions: ISubscription[];
}

const initialSubscriptionsState: ISubscriptionsState = {
  error: undefined,
  loading: false,
  subscriptions: [],
};

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: initialSubscriptionsState,
  reducers: {
    addSubscription: (state, _action: PayloadAction<IAddSubscriptionPayload>) => {
      state.error = undefined;
      state.loading = true;
    },
    addSubscriptionSuccess: (state, action: PayloadAction<IAddSubscriptionPayload>) => {
      state.loading = false;
      state.subscriptions = state.subscriptions
        .filter((item) => item.topic !== action.payload.topic)
        .concat(action.payload);
    },
    addSubscriptionError: (state, action: PayloadAction<IAddSubscriptionErrorPayload>) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    unsubscribeTopic: (state, _action: PayloadAction<IUnsubscribeTopicPayload>) => {
      state.error = undefined;
      state.loading = true;
    },
    unsubscribeTopicSuccess: (state, action: PayloadAction<IUnsubscribeTopicPayload>) => {
      state.loading = false;
      state.subscriptions = state.subscriptions.filter(
        (item) => item.topic !== action.payload.topic,
      );
    },
    unsubscribeTopicError: (state, action: PayloadAction<IUnsubscribeTopicErrorPayload>) => {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});

// ACTIONS
export const {
  addSubscription,
  addSubscriptionSuccess,
  addSubscriptionError,
  unsubscribeTopic,
  unsubscribeTopicSuccess,
  unsubscribeTopicError,
} = subscriptionsSlice.actions;

// SELECTORS
const getSubState = (state: RootState) => state.subscriptions as ISubscriptionsState;

const getSubscriptions = createSelector(getSubState, (state) => state.subscriptions);
const isLoading = createSelector(getSubState, (state) => state.loading);
const error = createSelector(getSubState, (state) => state?.error);

export const subscriptionSelectors = {
  getSubscriptions,
  isLoading,
  error,
};
// REDUCER
export default subscriptionsSlice.reducer;
