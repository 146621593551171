import { put, select, takeLatest } from 'redux-saga/effects';
import {
  applicationSelectors,
  getApplicationError,
  getApplicationSuccess,
  getUser,
  getUserError,
  getUserSuccess,
  logout,
  setToken,
  setTokenError,
  setTokenSuccess,
} from './application.slice';
import { PayloadAction } from '@reduxjs/toolkit';
import history from '../../history';
import { ICommunication, ISetTokenPayload, IUser } from './application.types';
import { resolveErrorText } from '../../helpers/ErrorTextHelper';
import axios, { AxiosError } from 'axios';
import { IError } from '../types';

export function* getApplicationSaga() {
  try {
    const { data } = yield axios.get('/application.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    yield put(getApplicationSuccess(data));
  } catch (err) {
    yield put(
      getApplicationError({
        error: undefined,
      }),
    );
  }
}

function* setTokenSaga(action: PayloadAction<ISetTokenPayload>) {
  try {
    const { token, redirectUrl } = action.payload;

    yield put(setTokenSuccess({ token }));

    if (redirectUrl) history.push(redirectUrl);
  } catch (err) {
    yield put(
      setTokenError({
        error: undefined,
      }),
    );
  }
}

function* getUserSaga() {
  try {
    const token: string = yield select(applicationSelectors.token);

    const { data } = yield axios.get(`/api/crm/registrations/${token}`);

    const user = {
      customer: data.customer,
      communications: data.communications.filter(
        (communication: ICommunication) => communication.status === 'CONFIRMED',
      ),
    } as IUser;

    yield put(getUserSuccess({ user }));
  } catch (err) {
    const axiosError = err as AxiosError;

    const error = {
      code: axiosError.response?.status,
      errorText: resolveErrorText(axiosError.response?.status, 'LOGIN'),
    } as IError;

    if (axiosError.response?.status === 401) {
      yield put(logout());
    } else {
      yield put(getUserError({ error }));
    }
  }
}

export default function* applicationWatcherSaga() {
  console.log('application watcher saga runs ...');
  yield takeLatest(setToken, setTokenSaga);
  yield takeLatest(getUser, getUserSaga);
}
