import { CommunicationType } from '../redux/application/application.types';

export const splitJoin = (data: (string | undefined)[], separator = ' ') =>
  data.filter((a) => a).join(separator);

interface ICommunicationTypeTexts {
  type: string;

  [key: string]: string;
}

export const resolveCommunicationTexts = (type: CommunicationType) => {
  let texts;

  switch (type) {
    case 'EMAIL': {
      texts = {
        type: 'E-Mail',
      } as ICommunicationTypeTexts;
      break;
    }
    default:
      break;
  }

  return texts;
};
