import { combineReducers } from '@reduxjs/toolkit';
import applicationReducer from './application/application.slice';
import registrationReducer from './registration/registration.slice';
import subscriptionsReducer from './subcriptions/subscriptions.slice';

const rootReducer = combineReducers({
  application: applicationReducer,
  registration: registrationReducer,
  subscriptions: subscriptionsReducer,
});

export default rootReducer;
