import React, { useState } from 'react';
import './Communication.css';
import { ICommunication } from '../../../redux/application/application.types';
import { resolveCommunicationTexts } from '../../../helpers/TextHelper';
import { Button } from '../../common';
import { Modal } from '../Modal';
import Icon from '@mdi/react';
import { mdiTrashCanOutline, mdiCheckCircleOutline } from '@mdi/js';

interface ICommunicationProps {
  communication: ICommunication;
  onDelete: () => void;
  loading?: boolean;
}

const Communication = ({ communication, loading, onDelete }: ICommunicationProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const texts = resolveCommunicationTexts(communication.type);

  return (
    <>
      <div className={'communication'}>
        <div className={'communication--content'}>
          <div className={'communication--content-type'}>
            <div className={'communication--content-type-text'}>{texts?.type}</div>
            <Icon
              className={'communication--content-type-confirmed-icon'}
              path={mdiCheckCircleOutline}
            />
          </div>
          <div>{communication.recipient}</div>
        </div>
        <div className={'communication--buttons'}>
          <Button size={'small'} onClick={() => setIsOpen(true)}>
            <Icon path={mdiTrashCanOutline} className={'communication--delete-icon'} />
          </Button>
        </div>
      </div>
      <Modal
        open={isOpen}
        loading={loading}
        title={`${texts?.type} Kanal abmelden`}
        description={`Möchten Sie sich wirklich von diesem ${texts?.type} Kommunikationskanal abmelden?`}
        onOk={async () => {
          await onDelete();
          setIsOpen(false);
        }}
        okButtonText={'Abmelden'}
        showCancelButton={true}
        cancelButtonText={'Abbrechen'}
        onCancel={() => setIsOpen(false)}
      />
    </>
  );
};

export default Communication;
