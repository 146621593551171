import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  confirmNotificationRegistration,
  initNotificationRegistration,
  registrationSelectors,
} from '../../../redux/registration/registration.slice';
import { useNavigate } from 'react-router-dom';
import Success from '../Success';
import { ConfirmationForm } from '../../components/ConfirmationForm';

interface IConfirmationProps {
  title?: string | JSX.Element;
  description?: string | JSX.Element;
  successTitle?: string | JSX.Element;
  successDescription?: string | JSX.Element;
}

const Confirmation = ({
  title,
  description,
  successTitle,
  successDescription,
}: IConfirmationProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const process = useAppSelector(registrationSelectors.process);
  const status = useAppSelector(registrationSelectors.status);
  const isLoading = useAppSelector(registrationSelectors.isLoading);

  const error = useAppSelector(registrationSelectors.error);

  useEffect(() => {
    if (!process) {
      navigate('/');
    }
  }, [process]);

  return status === 'CONFIRMED' ? (
    <Success title={successTitle} description={successDescription} />
  ) : (
    <>
      <ConfirmationForm
        onResend={() =>
          process &&
          dispatch(
            initNotificationRegistration({
              process: {
                recipient: process?.recipient,
                type: process?.type,
                status: process?.status,
              },
            }),
          )
        }
        title={title}
        description={description}
        onComplete={(code) => dispatch(confirmNotificationRegistration({ code: code }))}
        loading={isLoading}
        error={error}
      />
    </>
  );
};

export default Confirmation;
