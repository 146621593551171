import React, { ReactNode, useEffect, useState } from 'react';
import { DEFAULT_ERROR_TEXT } from '../../../helpers/ErrorTextHelper';
import { IError } from '../../../redux/types';
import './EMailForm.css';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { clearError, registrationSelectors } from '../../../redux/registration/registration.slice';
import { Button, Card } from '../../../components';
import useCountdown from '../../../hooks/useCountdown';
import dayjs from 'dayjs';
import { applicationSelectors } from '../../../redux/application/application.slice';

interface IEMailFormProps {
  onSubmit: (email: string) => void;
  submitButtonText?: string;
  extra?: ReactNode;
  loading?: boolean;
  error?: IError;
}

const EMailForm = ({ onSubmit, submitButtonText, loading, error, extra }: IEMailFormProps) => {
  const dispatch = useAppDispatch();
  const emailRegEx = /^\S+@\S+\.\S+$/;
  const initialEmail = useAppSelector(applicationSelectors.initialEMail);
  const [email, setEmail] = useState(initialEmail || '');
  const [isFocused, setIsFocused] = useState(false);

  const countdownRemaining = useAppSelector(registrationSelectors.countdownRemaining(dayjs()));
  const { countdown, startCountdown } = useCountdown(countdownRemaining);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (error && countdown === 0) {
      startCountdown(30);

      timeout = setTimeout(() => {
        dispatch(clearError());
      }, 29800);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [error]);

  return (
    <form
      className={'form'}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(email);

        setEmail('');
      }}
    >
      <Card className={'card--primary form'}>
        <div className={'form--content'}>
          <label
            className={clsx('form--label', {
              'form--label-focused': isFocused,
            })}
            htmlFor='email'
          >
            {'E-Mail-Adresse'}
          </label>
          <input
            autoFocus={true}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => setIsFocused(false)}
            className={'form--input'}
            type='email'
            name={'email'}
            id={'email'}
            required
            placeholder={'example@domain.com'}
            onChange={(event) => setEmail(event.currentTarget.value)}
            value={email}
          />
        </div>
        <>{extra}</>
        <>
          {error && (
            <Card className={'form--error'}>
              <p>{error.errorText || DEFAULT_ERROR_TEXT}</p>
            </Card>
          )}
        </>
        <Button
          disabled={countdown > 0 || !emailRegEx.test(email)}
          loading={loading}
          htmlType={'submit'}
          type={'primary'}
          block={true}
        >
          <>
            {countdown > 0 ? (
              <span>{`Bitte warten Sie noch ${countdown} ${
                countdown === 1 ? 'Sekunde' : 'Sekunden'
              }`}</span>
            ) : (
              <span>{`${submitButtonText}` || 'Senden'}</span>
            )}
          </>
        </Button>
      </Card>
    </form>
  );
};

export default EMailForm;
