import React from 'react';
import './Tooltip.css';

interface TooltipProps {
  text: string;
  children?: React.ReactNode;
}

const Tooltip = ({ text, children }: TooltipProps) => {
  return (
    <div className='tooltip-container'>
      {children}
      <span className='tooltip-text'>{text}</span>
    </div>
  );
};

export default Tooltip;
