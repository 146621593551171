import React from 'react';
import { CommunicationType } from '../../../redux/application/application.types';
import EMail from '../EMail';

interface IRegisterDelegatorProps {
  type: CommunicationType;
}

const RegisterDelegator = ({ type }: IRegisterDelegatorProps) => {
  let Component = null;

  switch (type) {
    case 'EMAIL': {
      Component = <EMail />;
      break;
    }
    default:
      break;
  }

  return Component;
};

export default RegisterDelegator;
