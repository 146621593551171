import React, { useEffect } from 'react';
import './UnsubscribeTopic.styles.css';
import { clsx } from 'clsx';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { applicationSelectors } from '../../redux/application/application.slice';
import { useNavigate } from 'react-router-dom';
import {
  subscriptionSelectors,
  unsubscribeTopic,
} from '../../redux/subcriptions/subscriptions.slice';
import { Button, Card, Description } from '../../components';
import { DEFAULT_ERROR_TEXT } from '../../helpers/ErrorTextHelper';
import Success from '../Register/Success';

const UnsubscribeTopic = () => {
  const token = useAppSelector(applicationSelectors.token);

  const subscriptions = useAppSelector(subscriptionSelectors.getSubscriptions);
  const isLoading = useAppSelector(subscriptionSelectors.isLoading);
  const error = useAppSelector(subscriptionSelectors.error);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) navigate('/');
  }, [token]);

  return (
    <>
      {token && subscriptions?.length > 0 && (
        <div className={clsx('unsubscribe-topic', 'home')}>
          <Card>
            <Description
              title={'Benachrichtigungen dieser Art entfernen'}
              description={
                <p>
                  Sie möchten keine weiteren Nachrichten von diesem Nachrichtentyp erhalten?
                  Bestätigen Sie durch einen Klick auf <b>{'"Abmelden"'}</b>. Falls Sie weiterhin
                  diese Nachrichten empfangen möchten oder Sie sich später entscheiden möchten,
                  können Sie diese Seite einfach schließen.
                </p>
              }
            />
            <>
              {error && (
                <Card className={clsx('form--error', 'unsubscribe-topic__error-ctr')}>
                  <p>{error.errorText || DEFAULT_ERROR_TEXT}</p>
                </Card>
              )}
            </>
            <Button
              autoFocus
              className={'unsubscribe-topic__submit-btn'}
              block
              onClick={() => {
                dispatch(unsubscribeTopic(subscriptions[0]));
              }}
              loading={isLoading}
              htmlType={'submit'}
              type={'primary'}
            >
              {'Abmelden'}
            </Button>
          </Card>
        </div>
      )}
      {subscriptions?.length === 0 && !error && (
        <Success
          redirectEnabled={false}
          title={'Nachrichten Art wurde erfolgreich entfernt'}
          description={
            <>
              <p>Sie erhalten zukünftig keine weiteren Nachrichten für diesen Nachrichtentyp.</p>
              <div className={'success--unsubscribe-info'}>
                <p>
                      <span>
                        {
                          'In der '
                        }
                      </span>
                  <Button type={'link'} onClick={() => navigate('/')}>
                    {'Übersicht'}
                  </Button>
                  <span>{' können Sie Ihre registrierte(n) E-Mail-Adresse(n) einsehen und bei Bedarf individuell abmelden.'}</span>
                </p>
              </div>
            </>
          }
        />
      )}
    </>
  );
};

export default UnsubscribeTopic;
