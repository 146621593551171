import { put, select, take, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import {
  initNotificationRegistration,
  initNotificationRegistrationSuccess,
  initNotificationRegistrationError,
  confirmNotificationRegistration,
  confirmNotificationRegistrationError,
  confirmNotificationRegistrationSuccess,
  registrationSelectors,
  clearError,
  updateMarketingAccepted,
} from './registration.slice';
import {
  applicationSelectors,
  getUser,
  getUserSuccess,
  logout,
} from '../application/application.slice';
import history from '../../history';
import {
  IConfirmNotificationRegistrationPayload,
  IInitNotificationRegistrationPayload,
  INotificationRegistrationProcess,
} from './registration.types';
import { resolveErrorText } from '../../helpers/ErrorTextHelper';
import { IError } from '../types';
import dayjs from 'dayjs';
import { ICommunicationConfig, IUser } from '../application/application.types';

function* initNotificationRegistrationSaga(
  action: PayloadAction<IInitNotificationRegistrationPayload>,
) {
  const created = dayjs().valueOf();

  const { recipient, type } = action.payload.process;

  try {
    const token: string = yield select(applicationSelectors.token);

    const { data } = yield axios.post(`/api/crm/registrations/${token}/email/register`, {
      recipient,
    });

    const communicationConfigs: ICommunicationConfig[] | undefined = yield select(
      applicationSelectors.communications,
    );

    const currentCommunicationTypeAllowedCount = communicationConfigs?.find(
      (c) => c.type === type,
    )?.allowedCount;

    if (currentCommunicationTypeAllowedCount === 1) {
      yield put(getUser());
      yield take(getUserSuccess);

      const user: IUser = yield select(applicationSelectors.user);

      const hasUserRegisteredCommunicationForType = !!user.communications.find(
        (c) => c.type === type && c.status === 'CONFIRMED',
      );

      const registeredCommunicationTypeRecipient = user.communications.find(
        (c) => c.type === type && c.status === 'CONFIRMED',
      )?.recipient;

      if (
        hasUserRegisteredCommunicationForType &&
        recipient !== registeredCommunicationTypeRecipient
      ) {
        history.push(`/register/email/registered/${registeredCommunicationTypeRecipient}`);

        return;
      }
    }

    const process = {
      id: data.id,
      recipient: recipient,
      type: type,
      status: 'PENDING',
      created: created,
    } as INotificationRegistrationProcess;

    yield put(initNotificationRegistrationSuccess({ process, created }));

    const retry = action.payload.retry;

    if (!retry) {
      history.push('/register/email/confirmation');
    }
  } catch (err) {
    const axiosError = err as AxiosError;

    const error = {
      code: axiosError.response?.status,
      errorText: resolveErrorText(axiosError.response?.status, 'REGISTRATION_EMAIL'),
    } as IError;

    if (axiosError.response?.status === 401) {
      yield put(logout());
    } else if (axiosError.response?.status === 409) {
      history.push(`/register/email/registered/${recipient}`);
    } else {
      yield put(initNotificationRegistrationError({ error, created }));
    }
  }
}

function* confirmNotificationRegistrationSaga(
  action: PayloadAction<IConfirmNotificationRegistrationPayload>,
) {
  try {
    const token: string = yield select(applicationSelectors.token);
    const process: INotificationRegistrationProcess = yield select(registrationSelectors.process);
    const code = action.payload.code;

    yield axios.post(`/api/crm/registrations/${token}/confirm`, { code });

    const response = {
      id: process.id,
      recipient: process.recipient,
      type: process.type,
      status: 'CONFIRMED',
    } as INotificationRegistrationProcess;

    yield put(confirmNotificationRegistrationSuccess({ process: response }));
  } catch (err) {
    const axiosError = err as AxiosError;

    const error = {
      code: axiosError.response?.status,
      errorText: resolveErrorText(axiosError.response?.status, 'REGISTRATION_CONFIRMATION'),
    } as IError;

    if (axiosError.response?.status === 401) {
      yield put(logout());
    } else {
      yield put(confirmNotificationRegistrationError({ error }));
    }
  }
}

function* sendMarketingAcceptanceSaga() {
  try {
    const token: string = yield select(applicationSelectors.token);
    const marketingAccepted: boolean = yield select(registrationSelectors.getMarketingAccepted);

    if (marketingAccepted) {
      yield axios.post(`/api/crm/registrations/${token}/marketingAcceptance`, {
        marketingAccepted: marketingAccepted,
      });
    }
  } catch (err) {
    const axiosError = err as AxiosError;

    const error = {
      code: axiosError.response?.status,
      errorText: resolveErrorText(axiosError.response?.status, 'MARKETING_ACCEPTANCE'),
    } as IError;

    if (axiosError.response?.status === 401) {
      yield put(logout());
    }

    console.log('Error in sendMarketingAcceptanceSaga: ', error);
  } finally {
    yield put(updateMarketingAccepted(false));
  }
}

function* clearRegistrationErrorSaga() {
  try {
    const error: IError = yield select(registrationSelectors.error);

    if (error != null) yield put(clearError());
  } catch (err) {
    console.log('error in clearRegistrationErrorSaga: ', err);
  }
}

export default function* registrationWatcherSaga() {
  console.log('registration watcher saga runs ...');

  yield takeLatest(initNotificationRegistration, initNotificationRegistrationSaga);

  yield takeLatest(confirmNotificationRegistration, confirmNotificationRegistrationSaga);
  yield takeLatest(confirmNotificationRegistrationSuccess, sendMarketingAcceptanceSaga);

  yield takeLatest(getUser, clearRegistrationErrorSaga);
}
