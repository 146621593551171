import React from 'react';

interface IRowProps {
  cols?: number;
  gap?: number;
  children: JSX.Element | JSX.Element[];
}

const Row = ({ children, cols = 1, gap = 8 }: IRowProps) => {
  return (
    <div
      className={'row'}
      style={{ gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`, gap: gap }}
    >
      {children}
    </div>
  );
};

export default Row;
