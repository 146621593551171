import React, { useCallback, useState } from 'react';
import './CommunicationsOverview.css';
import { ICommunication } from '../../../redux/application/application.types';
import axios, { AxiosError } from 'axios';
import { Button, Card, Row, Modal, Communication, Description } from '../../../components';
import {
  applicationSelectors,
  getUser,
  logout,
} from '../../../redux/application/application.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';

interface ICommunicationsOverviewProps {
  title?: string;
  communications: ICommunication[];
}

const CommunicationsOverview = ({ communications, title }: ICommunicationsOverviewProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const token = useAppSelector(applicationSelectors.token);
  const dispatch = useAppDispatch();

  const handleUnsubscribe = useCallback(
    async (id?: string) => {
      try {
        setIsLoading(true);
        const url = id
          ? `/api/crm/registrations/${token}/communications/${id}`
          : `/api/crm/registrations/${token}/communications`;

        await axios.delete(url);

        dispatch(getUser());

        setIsLoading(false);
      } catch (err) {
        const axiosError = err as AxiosError;

        if (axiosError.response?.status === 401) {
          dispatch(logout());
        } else if (axiosError.response?.status === 404) {
          dispatch(getUser());
        }

        setIsLoading(false);
      }
    },
    [token],
  );

  if (communications.length === 0) return null;

  return (
    <>
      <div className={'communications-overview'}>
        <Card>
          <Description
            title={title || 'Registrierte Kommunikationskanäle'}
            description={
              <p>
                <span>
                  Sie können sich jederzeit von einem oder auch allen registrierten
                  Kommunikationskanälen
                  <b> abmelden</b>. Über einen abgemeldeten Kommunikationskanal werden wir Sie in
                  Zukunft nicht mehr kontaktieren.
                </span>
              </p>
            }
          />
        </Card>
        <Row cols={1}>
          <>
            {communications.map((communication, index) => {
              return (
                communication.status === 'CONFIRMED' && (
                  <Card className={'card--primary space-1'} key={index}>
                    <Communication
                      communication={communication}
                      loading={isLoading}
                      onDelete={() => handleUnsubscribe(communication.id)}
                    />
                  </Card>
                )
              );
            })}
          </>
        </Row>
        {communications.length > 1 && (
          <div className={'communications-overview--buttons'}>
            <Button loading={isLoading} onClick={() => setIsOpen(true)}>
              <>
                <Icon
                  path={mdiTrashCanOutline}
                  className={'communications-overview--delete-icon'}
                />
                <span>{'alle Kanäle abmelden'}</span>
              </>
            </Button>
          </div>
        )}
      </div>
      <Modal
        open={isOpen}
        title={`Alle Kommunikationskanälen abmelden`}
        description={`Möchten Sie sich wirklich von allen Kommunikationskanälen abmelden?`}
        onOk={async () => {
          await handleUnsubscribe();
          setIsOpen(false);
        }}
        loading={isLoading}
        okButtonText={'Abmelden'}
        showCancelButton={true}
        cancelButtonText={'Abbrechen'}
        onCancel={() => setIsOpen(false)}
      />
    </>
  );
};

export default CommunicationsOverview;
