import { useEffect } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { applicationSelectors } from '../../../redux/application/application.slice';
import { useNavigate } from 'react-router-dom';

interface IProtectedRouteProps {
  protectedChild: JSX.Element;
}

const ProtectedRoute = ({ protectedChild }: IProtectedRouteProps) => {
  const user = useAppSelector(applicationSelectors.user);
  const token = useAppSelector(applicationSelectors.token);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user && !token) {
      navigate('/');
    }
  }, [user, token]);

  return protectedChild;
};

export default ProtectedRoute;
