import React, { useMemo } from 'react';
import './RegistrationOverview.css';
import { ICommunication, ICommunicationConfig } from '../../../redux/application/application.types';
import { Button, Card, Row, Description } from '../../../components';
import RegisterDelegator from '../../Register/RegisterDelegator';
import { useAppSelector } from '../../../redux/hooks';
import { applicationSelectors } from '../../../redux/application/application.slice';
import { splitJoin } from '../../../helpers/TextHelper';

interface IRegistrationsOverviewProps {
  communicationsConfigs?: ICommunicationConfig[];
  communications: ICommunication[];
}

const RegistrationsOverview = ({
  communicationsConfigs,
  communications,
}: IRegistrationsOverviewProps) => {
  const privacy = useAppSelector(applicationSelectors.privacy);
  const nav = useAppSelector(applicationSelectors.nav);
  const tenant = useAppSelector(applicationSelectors.tenant);
  const tenantShort = useAppSelector(applicationSelectors.tenantShort);
  const user = useAppSelector(applicationSelectors.user);

  const registerComponents = useMemo(() => {
    const Components: JSX.Element[] = [];

    communicationsConfigs?.forEach((communication, index) => {
      const confirmedCommunicationsForTypeCount = communications.reduce((acc, curr) => {
        if (curr.type === communication.type) {
          return curr.status === 'CONFIRMED' ? acc + 1 : acc;
        } else {
          return acc;
        }
      }, 0);

      if (communication.allowedCount > confirmedCommunicationsForTypeCount) {
        Components.push(<RegisterDelegator key={index} type={communication.type} />);
      }
    });

    return Components;
  }, [communicationsConfigs, communications]);

  const description = useMemo(() => {
    if (registerComponents.length === 0) {
      return (
        <p>
          <span>
            Danke, dass Sie sich für die digitale Kommunikation der
            <b> {tenant} </b>registriert haben. In Zukunft werden wir Sie über die von Ihnen
            hinterlegte<b> E-Mail-Adresse </b>kontaktieren.
          </span>
        </p>
      );
    }

    return (
      <p>
        <span>
          Danke, dass Sie sich für die digitale Kommunikation der
          <b> {tenant} </b>entschieden haben. Damit wir Sie in Zukunft digital kontaktieren können,
          registrieren Sie bitte Ihre
          <b> E-Mail-Adresse</b>.{' '}
          {(
            tenantShort === 'ewe' || tenantShort === 'testewe' || tenantShort === 'devewe' || tenantShort === 'devkwmk' || tenantShort === 'testkwmk' || tenantShort === 'kwmk'
          )
            ? `Wir nutzen Ihre E-Mail-Adresse ausschließlich als Ihr
          Messstellenbetreiber für die Prozesse der Zählerablesung und des Zählerwechsels.`
            : `Wir nutzen Ihre E-Mail-Adresse ausschließlich für Aufträge zu Ihren Zählern.`}
        </span>
      </p>
    );
  }, [registerComponents]);

  return (
    <div className={'registrations-overview'}>
      <Card>
        <Description
          title={
            user?.customer?.salutation ||
            `Willkommen ${
              user?.customer.company ||
              splitJoin([user?.customer.firstname, user?.customer.lastname])
            }`
          }
          description={description}
        />
      </Card>
      {registerComponents.length > 0 && (
        <Row cols={1}>
          <>{registerComponents}</>
          <>
            {(privacy || nav) && (
              <Card className={'registrations-overview--privacy'}>
                <div>
                  {privacy?.short && <span>{`${privacy.short} `}</span>}
                  {privacy?.externalUrl && (
                    <Button
                      ghost
                      type={'link'}
                      onClick={() => {
                        window.open(privacy.externalUrl, 'popup', 'width=600, height=600');
                      }}
                    >
                      {'(Datenschutzerklärung). '}
                    </Button>
                  )}
                  {nav?.short && <span>{`${nav.short} `}</span>}
                  {nav?.externalUrl && (
                    <Button
                      ghost
                      type={'link'}
                      onClick={() => {
                        window.open(nav.externalUrl, 'popup', 'width=600, height=600');
                      }}
                    >
                      {'(Niederspannungsanschlussverordnung). '}
                    </Button>
                  )}
                </div>
              </Card>
            )}
          </>
        </Row>
      )}
    </div>
  );
};

export default RegistrationsOverview;
