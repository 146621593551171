import React, { useEffect } from 'react';
import './Welcome.css';
import { useAppSelector } from '../../redux/hooks';
import { applicationSelectors } from '../../redux/application/application.slice';
import { useNavigate } from 'react-router-dom';
import { Card, Description } from '../../components';

const Welcome = () => {
  const user = useAppSelector(applicationSelectors.user);
  const token = useAppSelector(applicationSelectors.token);
  const tenant = useAppSelector(applicationSelectors.tenant);
  const navigate = useNavigate();

  useEffect(() => {
    if (user || token) {
      navigate('/home');
    }
  }, [user, token]);

  return (
    <div className={'welcome'}>
      {!user && !token && (
        <Card>
          <Description
            title={'Registrierung zur digitalen Kommunikation'}
            description={
              <p>
                <span>
                  Wir bedanken uns für Ihren Entschluss sich für die digitale Kommunikation der
                  <b> {tenant} </b>zu registrieren. Um sich anzumelden, scannen Sie bitte den Ihnen
                  zugestellten <b>QR-Code</b>.
                </span>
              </p>
            }
          />
        </Card>
      )}
    </div>
  );
};

export default Welcome;
