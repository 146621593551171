import React, { useEffect } from 'react';
import { applicationSelectors, getUser } from '../../redux/application/application.slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import CommunicationsOverview from '../components/CommunicationsOverview';
import { Card, Description, LoginFeedback } from '../../components';

const Unsubscribe = () => {
  const user = useAppSelector(applicationSelectors.user);
  const token = useAppSelector(applicationSelectors.token);

  const isLoading = useAppSelector(applicationSelectors.isLoading);
  const error = useAppSelector(applicationSelectors.error);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) navigate('/');

    dispatch(getUser());
  }, [token]);

  return (
    <>
      {!user && token && <LoginFeedback loading={isLoading} error={error} />}
      {user && (
        <div className={'home'}>
          {user.communications.length === 0 && (
            <Card>
              <Description
                title={'Registrierte Kommunikationskanäle abmelden'}
                description={<p>Sie haben keine registrierten Kommunikationskanäle.</p>}
              />
            </Card>
          )}
          <CommunicationsOverview
            title={'Registrierte Kommunikationskanäle abmelden'}
            communications={user.communications}
          />
        </div>
      )}
    </>
  );
};

export default Unsubscribe;
