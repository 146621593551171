import React from 'react';

import './RegisterFailed.css';

import { Card } from '../../../components';
import { CommunicationType } from '../../../redux/application/application.types';
import RegisterDelegator from '../RegisterDelegator';

interface RegisterFailedProps {
  title: string;
  description: string | JSX.Element;
  type: CommunicationType;
}

const RegisterFailed = ({ title, description, type }: RegisterFailedProps) => {
  return (
    <div className={'register-failed'}>
      <h2 className={'register-failed_title'}>{title}</h2>
      <Card className={'register-failed_description'}>
        <div>{description}</div>
      </Card>
      <RegisterDelegator type={type} />
    </div>
  );
};

export default RegisterFailed;
