import React, { useEffect, useState, useRef } from 'react';
import './ConfirmationForm.css';
import PinField from "react-pin-field";
import { DEFAULT_ERROR_TEXT } from '../../../helpers/ErrorTextHelper';
import { TailSpin } from 'react-loader-spinner';
import { IError } from '../../../redux/types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { clearError, registrationSelectors } from '../../../redux/registration/registration.slice';
import { Button, Card, Description } from '../../../components';
import useCountdown from '../../../hooks/useCountdown';
import dayjs from 'dayjs';

interface IConfirmationFormProps {
  title?: string | JSX.Element;
  description?: string | JSX.Element;
  onComplete: (code: string) => void;
  onResend: () => void;
  codeLength?: number;
  loading?: boolean;
  error?: IError;
}

const ConfirmationForm = ({
  onComplete,
  onResend,
  title,
  description,
  loading,
  error,
  codeLength = 6,
}: IConfirmationFormProps) => {
  const [code, setCode] = useState('');
  const [isSendDisabled, setIsSendDisabled] = useState(true);

  const refPinField = useRef<HTMLInputElement[]>([]);

  const dispatch = useAppDispatch();
  const countdownRemaining = useAppSelector(registrationSelectors.countdownRemaining(dayjs()));
  const { countdown, startCountdown } = useCountdown(countdownRemaining);

  useEffect(() => {

      refPinField.current[0]?.focus()

    return () => {
        // refPinField.current?.forEach(input => (input.value = ""))
        // keine ahnung wofür das gebraucht werden (sollte)
    };
  }, [refPinField.current]);

  useEffect(() => {
      if (error) {
        refPinField.current?.forEach(input => (input.value = ""))
        refPinField.current[0]?.focus()
      }
  }, [error, refPinField.current]);

  useEffect(() => {
      if (code == '') {
        refPinField.current[0]?.focus()
      }
  }, [onResend]);

  return (
    <div className={'confirmation-form'}>
      <Card>
        <Description
          title={title || 'Bestätigen'}
          description={description || 'Geben Sie den 6-stelligen Bestätigungscode ein.'}
        />
      </Card>
      <Card className={'card--primary'}>
        <form
          className={'confirmation-form--form'}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {(!error || (error && error.code !== 403 && error.code !== 410)) && (
            <label style={{ marginBottom: 12 }} htmlFor='code' className={'form--label'}>
              {'Ihr persönlicher Bestätigungscode'}
            </label>
          )}
          <div className={'input--code'}>
            {(!error || (error && error.code !== 403 && error.code !== 410)) && (
                <div
                  className={"input--code-container"}
                >
                  <PinField
                    ref={refPinField}

                    length={codeLength}
                    // validate={/^\d+$/}
                    type={'tel'}
                    pattern={'[0-9]*'}
                    inputMode={'numeric'}
                    onChange={(code) => {
                      if (error) dispatch(clearError());

                      if (code.length <= codeLength - 1) {
                        setIsSendDisabled(true);
                      }

                      setCode(code);
                    }}
                    onComplete={(code) => {
                      if (error) dispatch(clearError());

                      setIsSendDisabled(false);

                      setCode(code);
                    }}
                  />
                </div>
            )}
            <div className={'input--code-resend'}>
              {error && (
                <Card className={'confirmation-form--error'}>
                  <p>{error.errorText || DEFAULT_ERROR_TEXT}</p>
                </Card>
              )}
              <Button
                htmlType={'submit'}
                type={'primary'}
                onClick={() => {
                  onComplete(code);
                }}
                loading={loading}
                disabled={isSendDisabled || !!error}
              >
                <>
                  <span>{'Bestätigen'}</span>
                </>
              </Button>

              <Button
                type={error?.code === 403 || error?.code !== 410 ? 'primary' : 'default'}
                onClick={() => {
                  startCountdown(30);

                  setIsSendDisabled(true);

                  refPinField?.current.forEach(input => (input.value = ""))
                  setCode('');

                  onResend();
                }}
                loading={loading}
                disabled={countdown > 0}
              >
                <>
                  <span>{'Code erneut senden '}</span>
                  {countdown > 0 && <span>{`(${countdown}s)`}</span>}
                </>
              </Button>
            </div>
          </div>
        </form>
      </Card>
      {loading && (
        <div className={'confirmation-form--loading'}>
          <TailSpin wrapperStyle={{ marginTop: '.5rem' }} width={40} height={40} />
        </div>
      )}
    </div>
  );
};

export default ConfirmationForm;
