import { useCallback, useEffect, useRef, useState } from 'react';

const useCountdown = (initialCountdown = 0) => {
  const [countdown, setCountdown] = useState(initialCountdown);
  const intervalRef = useRef<NodeJS.Timer>();

  const startCountdown = useCallback((countdownTime?: number) => {
    if (countdownTime) {
      setCountdown(countdownTime);
    }

    intervalRef.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 0) {
          clearInterval(intervalRef.current);
          return 0;
        } else {
          return prev - 1;
        }
      });
    }, 1000);
  }, []);

  useEffect(() => {
    startCountdown();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return { countdown, startCountdown };
};

export default useCountdown;
