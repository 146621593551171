import React, { Fragment } from 'react';
import './Modal.css';
import { Dialog, Transition } from '@headlessui/react';
import { Button } from '../../common';
import { DEFAULT_ERROR_TEXT } from '../../../helpers/ErrorTextHelper';

interface IModalProps {
  open: boolean;
  title: string;
  description?: string;
  onOk: () => void;
  okButtonText?: string;
  showCancelButton: boolean;
  onCancel: () => void;
  cancelButtonText?: string;
  loading?: boolean;
  error?: boolean;
  errorText?: string;
}

const { Panel, Title, Description } = Dialog;

const Modal = ({
  open,
  title,
  description,
  onOk,
  okButtonText,
  onCancel,
  cancelButtonText,
  showCancelButton = false,
  loading = false,
  error = false,
  errorText,
}: IModalProps) => {
  return (
    <Transition
      show={open}
      enter={'modal-transition--enter'}
      enterFrom={'modal-transition--enter-from'}
      enterTo={'modal-transition--enter-to'}
      leave={'modal-transition--leave'}
      leaveFrom={'modal-transition--leave-from'}
      leaveTo={'modal-transition--leave-to'}
      as={Fragment}
    >
      <Dialog className={'modal'} onClose={onCancel}>
        <div aria-hidden={true} className={'modal--panel-container-backdrop'} />
        <div className={'modal--panel-container'}>
          <Panel className={'modal--panel'}>
            <Title>{title}</Title>
            <Description>{description}</Description>
            {error && <p className={'text--error'}>{errorText || DEFAULT_ERROR_TEXT}</p>}
            <div className={'modal--buttons'}>
              {showCancelButton && (
                <Button size={'medium'} onClick={onCancel}>
                  {cancelButtonText || 'Abbrechen'}
                </Button>
              )}
              <Button
                loading={loading}
                type={'danger'}
                onClick={onOk}
                size={'medium'}
                disabled={loading}
              >
                {okButtonText || 'Bestätigen'}
              </Button>
            </div>
          </Panel>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
