import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  initNotificationRegistration,
  registrationSelectors,
  updateMarketingAccepted,
} from '../../../redux/registration/registration.slice';
import { EMailForm } from '../../components/EMailForm';
import {
  applicationSelectors,
  deleteInitialEMail,
} from '../../../redux/application/application.slice';
import Icon from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';
import Tooltip from '../../../components/common/Tooltip/Tooltip';

const EMail = () => {
  const dispatch = useAppDispatch();
  const marketingTextShort = useAppSelector(applicationSelectors.getMarketingTextShort);
  const marketingTextInformation = useAppSelector(applicationSelectors.getMarketingTextInformation);

  const showMarketingAcceptanceCheckbox = useAppSelector(
    applicationSelectors.showMarketingAcceptanceCheckbox,
  );

  const isLoading = useAppSelector(registrationSelectors.isLoading);
  const error = useAppSelector(registrationSelectors.error);
  const status = useAppSelector(registrationSelectors.status);
  const marketingAccepted = useAppSelector(registrationSelectors.getMarketingAccepted);

  const extra = useMemo(() => {
    if (!showMarketingAcceptanceCheckbox) {
      return null;
    }

    return (
      <div style={{ display: 'flex', alignItems: 'flex-start', gap: 8, flexWrap: 'nowrap' }}>
        <p>
          <input
            type={'checkbox'}
            checked={marketingAccepted}
            onChange={(e) => {
              dispatch(updateMarketingAccepted(e.currentTarget.checked));
            }}
          />
        </p>
        <p>{marketingTextShort}</p>
        {marketingTextInformation && (
          <Tooltip text={marketingTextInformation}>
            <Icon className={'marketing-info--icon'} path={mdiInformationOutline} size={'18px'} />
          </Tooltip>
        )}
      </div>
    );
  }, [
    showMarketingAcceptanceCheckbox,
    marketingAccepted,
    marketingTextShort,
    marketingTextInformation,
  ]);

  return (
    <>
      <EMailForm
        error={status === undefined ? error : undefined}
        loading={isLoading}
        onSubmit={(email) => {
          dispatch(initNotificationRegistration({ process: { recipient: email, type: 'EMAIL' } }));
          dispatch(deleteInitialEMail());
        }}
        submitButtonText={'Registrieren'}
        extra={extra}
      />
    </>
  );
};

export default EMail;
