import { put, select, takeLatest } from 'redux-saga/effects';
import {
  addSubscription,
  addSubscriptionError,
  addSubscriptionSuccess,
  unsubscribeTopic,
  unsubscribeTopicError,
  unsubscribeTopicSuccess,
} from './subscriptions.slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { IAddSubscriptionPayload, IUnsubscribeTopicPayload } from './subscriptions.types';
import axios, { AxiosError } from 'axios';
import { IError } from '../types';
import { resolveErrorText } from '../../helpers/ErrorTextHelper';
import { applicationSelectors, logout } from '../application/application.slice';

// todo: finish implementation after self service functionality is available
function* addSubscriptionSaga(action: PayloadAction<IAddSubscriptionPayload>) {
  try {
    yield put(addSubscriptionSuccess(action.payload));
  } catch (err) {
    yield put(addSubscriptionError({}));
  }
}

function* unsubscribeTopicSaga(action: PayloadAction<IUnsubscribeTopicPayload>) {
  try {
    const topic = action.payload;
    const token: string = yield select(applicationSelectors.token);

    yield axios.post(`/public-api/crm/customers/${token}/unsubscription`, {
      unsubscriptions: [topic],
    });

    yield put(unsubscribeTopicSuccess(topic));
  } catch (err) {
    const axiosError = err as AxiosError;

    const error = {
      code: axiosError.response?.status,
      errorText: resolveErrorText(axiosError.response?.status, 'UNSUBSCRIBE_TOPIC'),
    } as IError;

    if (axiosError.response?.status === 401) {
      yield put(logout());
    } else {
      yield put(unsubscribeTopicError({ error }));
    }
  }
}

export default function* topicsWatcherSaga() {
  yield takeLatest(addSubscription, addSubscriptionSaga);
  yield takeLatest(unsubscribeTopic, unsubscribeTopicSaga);
}
