import React from 'react';
import './LoginFeedback.css';
import { TailSpin } from 'react-loader-spinner';
import { DEFAULT_ERROR_TEXT } from '../../../helpers/ErrorTextHelper';
import { IError } from '../../../redux/types';
import { Card, Description } from '../../common';

interface ILoginFeedbackProps {
  loading: boolean;
  error?: IError;
}

const LoginFeedback = ({ loading, error }: ILoginFeedbackProps) => {
  return (
    <div className={'login'}>
      {loading && (
        <Card>
          <Description
            titleClass={'login--loading'}
            title={
              <>
                <span>Anmeldung</span>
                <TailSpin wrapperStyle={{ display: 'inline-flex' }} width={32} height={32} />
              </>
            }
            description={'Sie werden angemeldet. Bitte warten.'}
          />
        </Card>
      )}
      {error && (
        <Card>
          <h2>{'Anmeldung fehlgeschlagen'}</h2>
          <p>{error.errorText || DEFAULT_ERROR_TEXT}</p>
        </Card>
      )}
    </div>
  );
};

export default LoginFeedback;
