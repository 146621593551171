import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  applicationSelectors,
  setClosableReferer,
  setInitialEMail,
  setToken,
} from '../../redux/application/application.slice';
import { useAppSelector } from '../../redux/hooks';
import { initNotificationRegistration } from '../../redux/registration/registration.slice';
import { addSubscription } from '../../redux/subcriptions/subscriptions.slice';

const Login = () => {
  const isInitialized = useAppSelector(applicationSelectors.initialized);

  const { token } = useParams();
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isInitialized) return;

    if (!token) {
      navigate('/');
      return;
    }

    let redirectUrl: string | undefined = '/';

    if (pathname.includes('/topics/unsubscribe')) {
      redirectUrl = '/topics/unsubscribe';
    } else if (pathname.includes('/unsubscribe')) {
      redirectUrl = '/unsubscribe';
    }

    const params = new URLSearchParams(search);

    if (params.has('ref')) {
      dispatch(setClosableReferer(true));
    }

    if (params.get('email') != null && params.get('skipEmailRegistrationForm') === 'true') {
      dispatch(setToken({ token }));
      dispatch(
        initNotificationRegistration({
          process: { recipient: params.get('email')!, type: 'EMAIL' },
        }),
      );
      return;
    }

    dispatch(setToken({ token, redirectUrl }));

    if (params.get('email') != null) {
      dispatch(setInitialEMail(params.get('email')));
      return;
    }

    if (redirectUrl?.includes('topics')) {
      if (params.get('topic') != null) {
        dispatch(addSubscription({ topic: params.get('topic')! }));
        return;
      }
    }
  }, [token, isInitialized]);

  return null;
};

export default Login;
