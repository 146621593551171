import React from 'react';
import './Success.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Description } from '../../../components';
import Icon from '@mdi/react';
import { mdiCheckCircleOutline } from '@mdi/js';
import { useAppSelector } from '../../../redux/hooks';
import { applicationSelectors } from '../../../redux/application/application.slice';

interface ISuccessProps {
  title?: string | JSX.Element;
  description?: string | JSX.Element;
  redirectEnabled?: boolean;
}

const Success = ({ title, description, redirectEnabled = true }: ISuccessProps) => {
  const navigate = useNavigate();
  const isClosable = useAppSelector(applicationSelectors.isClosable);
  const { email } = useParams();

  return (
    <div className={'success'}>
      <Card>
        <div className={'success--wrapper'}>
          <div className={'success--icon'}>
            <Icon path={mdiCheckCircleOutline} />
          </div>
          <div>
            <Description
              title={<>{title || (email && 'Vielen Dank!') || 'Erfolgreich'}</>}
              description={
                description ||
                (email && (
                  <>
                    <p>
                      <span>
                        Die E-Mail-Adresse<b> {email} </b>wurde bereits erfolgreich für die digitale
                        Kommunikation registriert. Sie können die Seite jetzt schließen.
                      </span>
                    </p>
                    {isClosable && (
                      <div style={{ marginTop: 24 }}>
                        <Button block={true} type={'primary'} onClick={() => window.close()}>
                          Seite jetzt schließen
                        </Button>
                      </div>
                    )}
                  </>
                )) ||
                `Der Prozess wurde erfolgreich abgeschlossen.`
              }
            />
            {redirectEnabled && (
              <>
                <div className={'success--unsubscribe-info'}>
                  <p>
                    <span>
                      {
                        'Natürlich können Sie sich jederzeit wieder von der digitalen Kommunikation abmelden. Jede gesendete Kommunikation enthält einen Link, den Sie für die Abmeldung vom jeweiligen Nachrichtentyp verwenden können. Zusätzlich können Sie in der '
                      }
                    </span>
                    <Button type={'link'} onClick={() => navigate('/')}>
                      {'Übersicht'}
                    </Button>
                    <span>{' Ihre registrierte E-Mail-Adresse entfernen.'}</span>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Success;
