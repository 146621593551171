import React from 'react';
import { Outlet } from 'react-router-dom';
import { Logo, Footer } from '../../components';
import { useAppSelector } from '../../redux/hooks';
import { applicationSelectors } from '../../redux/application/application.slice';
import { EventSourceHandler } from '../components/EventSourceHandler';

const Root = () => {
  const privacy = useAppSelector(applicationSelectors.privacy);
  const imprint = useAppSelector(applicationSelectors.imprint);

  return (
    <>
      <header>
        <EventSourceHandler type={'EMAIL'} />
        <div className={'header-content'}>
          <Logo />
        </div>
      </header>
      <main>
        <div className={'main-content'}>
          <Outlet />
        </div>
      </main>
      <footer>
        <Footer privacy={privacy} imprint={imprint} />
      </footer>
    </>
  );
};

export default Root;
