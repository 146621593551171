import React from 'react';
import './Error.css';
import { Button, Card, Description } from '../../components';
import { useNavigate } from 'react-router-dom';

interface IErrorProps {
  title?: string;
  description?: string | JSX.Element;
  redirectUrl?: string;
  redirectButtonText?: string;
}

const Error = ({ title, description, redirectUrl, redirectButtonText }: IErrorProps) => {
  const navigate = useNavigate();

  return (
    <div className={'error'}>
      <Card>
        <Description
          title={title || 'Ups!'}
          description={description || 'Ein unerwarteter Fehler ist aufgetreten.'}
        />
        <>
          {redirectUrl && redirectButtonText && (
            <div className={'error--redirect'}>
              <Button block type={'primary'} onClick={() => navigate(redirectUrl)}>
                {redirectButtonText}
              </Button>
            </div>
          )}
        </>
      </Card>
    </div>
  );
};

export default Error;
