import { call, put, spawn } from 'redux-saga/effects';
import applicationWatcherSaga, { getApplicationSaga } from './application/application.saga';
import registrationWatcherSaga from './registration/registration.saga';
import subscriptionsWatcherSaga from './subcriptions/subscriptions.saga';
import { initializeApplication } from './application/application.slice';

export default function* rootSaga() {
  yield call(getApplicationSaga);

  yield spawn(applicationWatcherSaga);
  yield spawn(registrationWatcherSaga);
  yield spawn(subscriptionsWatcherSaga);

  yield put(initializeApplication());
}
