import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Root from './routes/Root';
import Welcome from './routes/Welcome';
import Error from './routes/Error';
import ProtectedRoute from './routes/components/ProtectedRoute';
import Home from './routes/Home';
import Logout from './routes/Logout';
import Login from './routes/Login';
import Confirmation from './routes/Register/Confirmation';
import { useAppSelector } from './redux/hooks';
import { registrationSelectors } from './redux/registration/registration.slice';
import Unsubscribe from './routes/Unsubscribe';
import RegisterFailed from './routes/Register/RegisterFailed/RegisterFailed';
import Success from './routes/Register/Success';
import UnsubscribeTopic from './routes/UnsubscribeTopic';
import { Button } from './components';
import { applicationSelectors } from './redux/application/application.slice';

const App = () => {
  const process = useAppSelector(registrationSelectors.process);
  const isClosable = useAppSelector(applicationSelectors.isClosable);

  return (
    <Routes>
      <Route path={'/'} element={<Root />}>
        <Route index element={<Welcome />} />
        <Route path={'/home'} element={<Home />} />
        <Route
          path={'/register/email/confirmation'}
          element={
            <ProtectedRoute
              protectedChild={
                <Confirmation
                  title={'E-Mail-Adresse bestätigen'}
                  description={
                    <p>
                      <span>
                        Es wurde ein Code an die E-Mail-Adresse<b> {process?.recipient} </b>
                        gesendet. Bitte geben Sie den 6-stelligen Code zur Bestätigung Ihrer
                        E-Mail-Adresse ein. Falls Sie keinen Code erhalten haben, klicken Sie bitte
                        auf <b>{'"Code erneut senden"'}</b>.
                      </span>
                    </p>
                  }
                  successTitle={'Vielen Dank!'}
                  successDescription={
                    <>
                      <p>
                        <span>
                          Die E-Mail-Adresse<b> {process?.recipient} </b>wurde erfolgreich für die
                          digitale Kommunikation registriert.{' '} Sie können die Seite jetzt schließen.
                        </span>
                      </p>
                      {isClosable && (
                        <div style={{marginTop: 24}}>
                          <Button block={true} type={'primary'} onClick={() => window.close()}>
                            Seite jetzt schließen
                          </Button>
                        </div>
                      )}
                    </>
                  }
                />
              }
            />
          }
        />
        <Route
          path={'/register/email/registered/:email'}
          element={<ProtectedRoute protectedChild={<Success />} />}
        />
        <Route
          path={'/register/email/failed'}
          element={
            <ProtectedRoute
              protectedChild={
                <RegisterFailed
                  title={'Registrierung der E-Mail-Adresse fehlgeschlagen'}
                  type={'EMAIL'}
                  description={
                    <p>
                      An die E-Mail-Adresse<b> {process?.recipient} </b>konnte keine E-Mail gesendet
                      werden.
                      <br />
                      Bitte prüfen Sie die eingegebene E-Mail-Adresse auf Tippfehler und stellen Sie
                      sicher, dass Ihr Postfach nicht voll ist.
                    </p>
                  }
                />
              }
            />
          }
        />
        <Route path={'/topics/unsubscribe'} element={<UnsubscribeTopic />} />
        <Route path={'/unsubscribe'} element={<Unsubscribe />} />
        <Route path={'/logout'} element={<ProtectedRoute protectedChild={<Logout />} />} />
        <Route
          path={'/*'}
          element={
            <Error
              title={'Seite nicht gefunden'}
              description={<p>Diese Seite konnte nicht gefunden werden.</p>}
              redirectUrl={'/'}
              redirectButtonText={'Zur Startseite'}
            />
          }
        />
      </Route>
      <Route path={'/m/:token/*'} element={<Login />} />
    </Routes>
  );
};

export default App;
