import React, { useEffect } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { logout } from '../../redux/application/application.slice';

const Logout = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logout());
  }, []);

  return <></>;
};

export default Logout;
