import React, { useEffect, useRef } from 'react';
import './Home.css';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { applicationSelectors, getUser } from '../../redux/application/application.slice';
import { useNavigate } from 'react-router-dom';
import CommunicationsOverview from '../components/CommunicationsOverview';
import RegistrationsOverview from '../components/RegistrationsOverview';
import { LoginFeedback } from '../../components';

const Home = () => {
  const mounted = useRef(true);
  const dispatch = useAppDispatch();
  const user = useAppSelector(applicationSelectors.user);
  const communications = useAppSelector(applicationSelectors.communications);
  const token = useAppSelector(applicationSelectors.token);
  const isLoading = useAppSelector(applicationSelectors.isLoading);

  const error = useAppSelector(applicationSelectors.error);

  const navigate = useNavigate();

  useEffect(() => {
    if (mounted.current) {
      if (!token) navigate('/');

      dispatch(getUser());
    }

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <>
      {!user && token && <LoginFeedback loading={isLoading} error={error} />}
      {user && (
        <div className={'home'}>
          <RegistrationsOverview
            communicationsConfigs={communications}
            communications={user.communications}
          />
          <CommunicationsOverview communications={user.communications} />
        </div>
      )}
    </>
  );
};

export default Home;
