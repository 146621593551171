import React from 'react';
import { IImprintConfig, IPrivacyConfig } from '../../../redux/application/application.types';
import { Button } from '../../common';
import './Footer.css';

interface IFooterProps {
  privacy?: IPrivacyConfig;
  imprint?: IImprintConfig;
}

const Footer = ({ privacy, imprint }: IFooterProps) => {
  if (!privacy?.externalUrl && !imprint?.short && !imprint?.externalUrl) return null;

  return (
    <div className={'footer'}>
      <>
        {imprint?.short && (
          <div className={'footer--imprint-short'}>
            <span>{`${imprint.short}`}</span>
            {(imprint?.externalUrl || privacy?.externalUrl) && <span>{' | '}</span>}
          </div>
        )}
        {imprint?.externalUrl && (
          <div className={'footer--imprint'}>
            <Button
              ghost
              type={'link'}
              onClick={() => {
                window.open(imprint?.externalUrl, 'popup', 'width=600, height=600');
              }}
            >
              {'Impressum'}
            </Button>
          </div>
        )}
        {privacy?.externalUrl && (
          <div className={'footer--privacy'}>
            {(imprint?.short || imprint?.externalUrl) && <span>{' | '}</span>}
            <Button
              type={'link'}
              ghost
              onClick={() => {
                window.open(privacy?.externalUrl, 'popup', 'width=600, height=600');
              }}
            >
              {'Datenschutz'}
            </Button>
          </div>
        )}
      </>
    </div>
  );
};

export default Footer;
