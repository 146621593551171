import React from 'react';
import clsx from 'clsx';
import { TailSpin } from 'react-loader-spinner';
import './Button.css';

interface IButtonProps {
  className?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  children?: JSX.Element | string;
  type?: 'primary' | 'default' | 'danger' | 'link';
  size?: 'small' | 'medium';
  ghost?: boolean;
  block?: boolean;
  disabled?: boolean;
  loading?: boolean;
  autoFocus?: boolean;
}

const Button = ({
  onClick,
  children,
  htmlType = 'button',
  type = 'default',
  size,
  ghost = false,
  block = false,
  disabled = false,
  loading = false,
  autoFocus = false,
  className,
}: IButtonProps) => {
  return type === 'link' ? (
    <a
      className={clsx(
        'button--link',
        {
          'button--ghost': ghost,
          'button--disabled': disabled || loading,
        },
        className,
      )}
      onClick={(event) => {
        event.preventDefault();
        onClick && onClick();
      }}
    >
      {children}
    </a>
  ) : (
    <button
      autoFocus={autoFocus}
      type={htmlType}
      className={clsx(`button button--text`, className, {
        'button--small': size === 'small',
        'button--medium': size === 'medium',
        'button--ghost': ghost,
        'button--primary': !disabled && type === 'primary',
        'button--default': !disabled && type === 'default',
        'button--danger': !disabled && type === 'danger',
        'button--disabled': disabled || loading,
      })}
      style={{ width: block ? '100%' : 'auto' }}
      onClick={onClick}
      disabled={disabled || loading}
    >
      <div style={{ display: 'inline-flex', alignItems: 'center', columnGap: 8 }}>
        <TailSpin height={18} width={18} color={'#999'} visible={loading} />
        <span style={{ display: 'flex', alignItems: 'center', columnGap: '.25rem' }}>
          {children}
        </span>
      </div>
    </button>
  );
};

export default Button;
